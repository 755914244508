import "./Block.scss";
import {
  glide,
  Anchors,
  Breakpoints,
  Controls,
  LazyLoad,
  Swipe,
} from "@olmokit/core/glide";
import { $ } from "@olmokit/dom";
import { NewsCard } from "./Card";

/**
 * Component: News
 */
export function NewsBlock() {
  console.log("NewsBlock mounted.");

  NewsCard();

  const glideOptions = {
    type: "carousel",
    rewind: false,
    perView: 3,
    startAt: 0,
    gap: 0,
    animationDuration: 800,
    animationTimingFunc: "cubic-bezier(.13,.23,.77,1)",
    breakpoints: {
      1024: {
        perView: 2,
        startAt: 0
      },
      600: {
        perView: 1
      }
    }
  };

  const slider = $('.NewsBlock:slider');

  const sliderImages = glide(slider, glideOptions);

  sliderImages.mount({
      Swipe,
      LazyLoad,
      Anchors,
      Controls,
      Breakpoints
  });  
}
