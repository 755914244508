import "./Home.scss";
import { $, $all, forEach, on } from "@olmokit/dom";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";

/**
 * Component: Products
 */
export function ProductHome() {
  console.log("ProductHome mounted.");

  const $grid = $('.ProductGrid:');
  const msnry = new Masonry( $grid, {
    // options...
    itemSelector: '.grid-item',
    columnWidth: '.grid-sizer'
  });

  imagesLoaded( $grid ).on( 'progress', function() {
    // layout Masonry after each image loads
    msnry.layout();
  });

  const $cards = $all('.ProductGrid:item');
  forEach($cards, (card) => {
    on(card, 'click',  () => {
      const link = $('a', card).getAttribute('href');
      location.href = link;
    })
  });

}
