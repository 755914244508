
import { I18nLinks } from "@olmokit/core/i18n/links";
import {
  $, $all,
  addClass,
  removeClass,
  on,
  off,
  setVendorCSS,
} from "@olmokit/dom";
import { parseSize } from "@olmokit/core/helpers/jss";
import gsap from "gsap";
import { WindowDetect } from "components/WindowDetect";
import { InputMenu } from "components/Search";

import "components/Hamburger";
// import "utils/logo.scss";
import "./index.scss";

/**
 * Component: Header
 *
 */
export function Header() {
  I18nLinks();
  InputMenu();

  const customOptions = {}

  const sizeMenu = WindowDetect() >= 1200 ? '50%' : '80%';
  
  const $root = $('.Header:');
  if (!$root) {
    return;
  }

  const options = {
    styles: {
      expandTime: 300,
    },
    ...customOptions,
  };
  const expandTime = parseSize(options.styles.expandTime);
  const $toggle = $(".Header:toggle", $root);
  const $collapsable = $(".Header:collapse", $root);
  const $collapsableInner = $(".Header:nav", $root);

  const $mainmenu = $all(".Header:nav li", $root);
  const $divider = $all(".Header:nav .divider", $root);

  /** @type {HeaderStatus} */
  let status = "collapsed";

  // localeSwitch();

  // default status as collapsed
  _setStatus("collapsed");

  // immediately clamp the collpsable header part, it won't never be open
  // at this point.

  if ($collapsable) {
    setVendorCSS($collapsable, "transitionDuration", expandTime);
  }

  if ($toggle) {
    on($toggle, "click", handleToggle);
    on(document, "click", handleClickOutside);
  }

  /**
   * Handle click outside
   *
   * @param {MouseEvent} event
   */
  function handleClickOutside(event) {
    if (status === "expanded") {
      // @ts-ignore
      if (!$root.contains(event.target)) {
        collapse();
      }
    }
  }

  /**
   * Handle toggle
   */
  function handleToggle() {
    if (status !== "expanded") {
      expand();
    } else {
      collapse();
    }
  }

  /**
   * Expand
   *
   */
  function expand() {
    // first expand the background
    // $collapsable.style.width = '100%';
    gsap.to($collapsable, {duration: 0.3, width: '100%'});
    gsap.to($collapsableInner, {duration: 0.3, width: sizeMenu, delay: 0.2});
    _setStatus("expanding");

    gsap.to($mainmenu, {
      delay: 0.4,
      x: 10,
      opacity: 1,
      stagger: 0.1
    });    

    gsap.to($divider, {
      delay: 0.4,
      x: 10,
      width: '20px',
      stagger: 0.1
    });        

    // then slide in the collapsable
    setTimeout(() => {
      addClass($collapsable, "is-in");
      _setStatus("expanded");
    }, expandTime);

    // then we are expanded
    setTimeout(() => {
      _setStatus("expanded");
    }, expandTime * 1.5);

    addClass($toggle, "is-active");
  }

  /**
   * Collapse
   *
   */
  function collapse() {
    // first slide out the collapsable
    gsap.to($collapsableInner, {duration: 0.3, width: 0});
    gsap.to($collapsable, {duration: 0.3, width: 0, delay: 0.2});
    removeClass($collapsable, "is-in");
    _setStatus("expanding");

    gsap.to($mainmenu, {
      x: 0,
      opacity: 0,
      stagger: 0.1
    });     

    gsap.to($divider, {
      delay: 0.4,
      x: 0,
      width: 0,
      stagger: 1
    });      

    // then we are collapsed
    setTimeout(() => {
      $collapsable.style.width = "0";
      _setStatus("collapsed");
    }, expandTime * 1.5);

    removeClass($toggle, "is-active");
  }

  /**
   * Set status
   *
   * @param {HeaderStatus} newValue
   */
  function _setStatus(newValue) {
    status = newValue;
    $root.setAttribute("data-status", newValue);
  }

  /**
   * Destroy
   *
   */
  function destroy() {
    off($toggle, "click", handleToggle);
    off(document, "click", handleClickOutside);
  }

}
