import "./index.scss";
import {
  glide,
  Anchors,
  Breakpoints,
  Controls,
  LazyLoad,
  Swipe,
} from "@olmokit/core/glide";
import { $ } from "@olmokit/dom";

/**
 * Component: Whyifi
 */
export function Whyifi() {
  console.log("Whyifi mounted.");

  const glideOptions = {
    type: "carousel",
    rewind: false,
    perView: 1,
    startAt: 0,
    gap: 0,
    animationDuration: 800,
    animationTimingFunc: "cubic-bezier(.13,.23,.77,1)"
  };

  const slider = $('.Whyifi:slider');

  const sliderImages = glide(slider, glideOptions);

  sliderImages.mount({
      Swipe,
      LazyLoad,
      Anchors,
      Controls,
      Breakpoints
  });   
}
