import "./index.scss";
import {
  glide,
  Anchors,
  Autoplay,
  Breakpoints,
  Controls,
  LazyLoad,
  Swipe,
} from "@olmokit/core/glide";
import "@olmokit/core/glide/bullets";
import { $, $all, on, forEach } from "@olmokit/dom";
import gsap from "gsap";

/**
 * Component: SliderHome
 */
export function SliderHome() {
  console.log("SliderHome mounted.");

  const glideOptions = {
    type: "carousel",
    autoplay: 5000,
    perView: 1,
    focusAt: 0,
    startAt: 0,
    // slideWidth: slidewidth,
    hoverpause: false,
    gap: 0,
    animationDuration: 800,
    animationTimingFunc: "cubic-bezier(.13,.23,.77,1)",
  };

  const sliderContainer = $(".SliderHome:container");
  const sliders = glide(sliderContainer, glideOptions);

  let colorSlideCount = 0;
  const slideColor = $all('.SliderHome:color_slide');  
  const slideProduct = $all('.SliderHome:product_slide');  

  forEach(slideProduct, (slide) => {
    if(!slide.classList.contains('activeProduct')){
      gsap.set(slide, { duration: 0.4, opacity: 0, scale: 1.2});
    }
  });

  const bullets = $all('.glide__bullet');
  forEach(bullets, (e) => {
    on(e, 'click', () => {

      if(!e.classList.contains('is-active')){
        /** 
         * To keep track of what happen in the different logic of interaction 
         * declare this variable and use it to watch between swip and click bullets 
         * */
        const activeColor = $('.activeColor');
        const activeProduct = $('.activeProduct');
        const attr = parseInt(e.getAttribute('data-glide-dir').replace('=', ''));
  
        gsap.to(activeColor, { duration: 0.4, opacity: 0, delay: 0.3});
        gsap.to(slideColor[attr], { duration: 0.4, opacity: 1});

        gsap.to(activeProduct, { duration: 0.4, opacity: 0, scale: 1.2});
        gsap.to(slideProduct[attr], { duration: 0.4, opacity: 1, scale: 1});        
  
        activeColor.classList.remove('activeColor');
        slideColor[attr].classList.add('activeColor');

        activeProduct.classList.remove('activeProduct');
        slideProduct[attr].classList.add('activeProduct');

        colorSlideCount = attr;
      }

    });
  });

  sliders.on('run', function(e) {
    /** Get the direction of the slide transition */
    const direction = e.direction;
    /** If the transition is right and the counter is minor the sliderColor length */
    if(direction == ">" && colorSlideCount < (slideColor.length-1)){
      slide('plus');
    } else if(direction == "<" && colorSlideCount > 0) {
      slide('minus');
    } else if(direction == ">" && colorSlideCount === (slideColor.length-1)) {
      slide('zero');
    } else if(direction == "<" && colorSlideCount === 0) {
      slide('spin');
    }
  }); 

  function slide(type) {
    gsap.to(slideColor[colorSlideCount], { duration: 0.4, opacity: 0, delay: 0.4});
    slideColor[colorSlideCount].classList.remove('activeColor');

    gsap.to(slideProduct[colorSlideCount], { duration: 0.4, opacity: 0, scale: 1.2});
    slideProduct[colorSlideCount].classList.remove('activeProduct');

    if(type === 'plus'){
      colorSlideCount++;
    }else if(type === 'minus'){
      colorSlideCount--;
    }else if(type === 'zero'){
      colorSlideCount = 0;
    } else if(type == 'spin'){
      colorSlideCount = slideProduct.length-1;
    }    

    gsap.to(slideColor[colorSlideCount], { duration: 0.4, opacity: 1});
    slideColor[colorSlideCount].classList.add('activeColor');

    gsap.to(slideProduct[colorSlideCount], { duration: 0.4, opacity: 1, scale: 1});
    slideProduct[colorSlideCount].classList.add('activeProduct');
  }

  sliders.mount({
    Swipe,
    LazyLoad,
    Anchors,
    Controls,
    Breakpoints,
    Autoplay
  });


}
