import "@olmokit/core/debug/api";
import "@olmokit/core/reboot/index.scss";
import "@olmokit/core/grid/index.scss";
import "@olmokit/core/typography/reset.scss";
import "@olmokit/core/icon/index.scss";
import LazyLoad from "@olmokit/core/lazy";
// import "@olmokit/core/img";
import { Header } from "components/Header";
import { RetreiveData } from "components/WindowDetect";
import { Selectlang } from "components/Selectlang";
import "components/Footer";
import "./index.scss";
import "utils/btn.scss";
import "utils/fonts.scss";
import "utils/logo.scss";

import { Button } from "components/Button";

Header();
Button();
RetreiveData();
Selectlang();

new LazyLoad();
