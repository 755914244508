/**
 * @file Route: home
 *
 * This file is compiled as a standalone entrypoint and it is included by
 * default in its blade template.
 * This is a "standard" route, it does not have a structure to follow, you are
 * sure the DOM is always ready and the JS will only be executed in this route.
 */

import "./index.scss";
import "layouts/main";

import { SliderHome } from "components/SliderHome";
import { SliderImages } from "components/SliderImages";
import { SliderSector } from "components/SliderSector";
import { BlockArredo } from "components/BlockArredo";
import { NewsBlock } from "components/News/Block";
import { Whyifi } from "components/Whyifi";
import { ProductHome } from "components/Product";

console.log("Route home/index.js mounted.");

SliderHome();
SliderImages();
SliderSector();
BlockArredo();
NewsBlock();
Whyifi();
ProductHome();