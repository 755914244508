import autoComplete from "@tarekraafat/autocomplete.js";
import Result from "./Result";
import { closeCleanSearch } from "./InputMenu";
import { $, on } from "@olmokit/dom";
import { globalConf, globalData } from "@olmokit/core/data";
import "./Search.scss";

export function feedSearch(value) {

    const search = $("#searchbar");
    search.value = value;

}

export function urlQuery() {

    const url = window.location.href;
    const urlUrl = new URL(url);
    const params = new URLSearchParams(urlUrl.search.slice(1));
    let value;
  
    for (let p of params) {
      value = p[1];
    }
  
    return value;
  
}

export function setQuery(value) {
    const url = window.location.href;
    const urlUrl = new URL(url);
    const params = new URLSearchParams(urlUrl.search.slice(1));

    params.set('query', value);
    window.history.replaceState({}, "", `${location.pathname}?${params}`);    
}

export function redirectTo(value) {
    const appurl = globalConf.baseUrl +'/'+ globalConf.locale;
    const url = window.location.href;
    const urlUrl = new URL(url);
    const params = new URLSearchParams(urlUrl.search.slice(1));    

    params.set('query', value);
    document.location = appurl + `/search/?${params}`;
}

export default function Search(searchbar, buttonsearch) {

    const searchinput = $(searchbar);
    const button = $(buttonsearch);
    const pageSearch = globalData.search;    

    /**
     * Search by button
     */

    on(button, 'click', (e) => {

        e.preventDefault();
        setQuery(searchinput.value);

        if (!pageSearch) {
            const query = urlQuery();
            redirectTo(query);
        } else {
            closeCleanSearch();
            Result(searchinput.value);
        }

    });

    // const $searchheader = $('#searchbarheader');
    on(searchinput, 'keyup', function(e){
        if (searchinput === document.activeElement) {
            if (e.key === 'Enter' || e.keyCode === 13) {
                redirectTo(searchinput.value);
            }
        }        
    });
    // on(searchinput, 'keyup', function (e) {
    //     if (e.key === 'Enter' || e.keyCode === 13) {
    //         redirectTo(searchinput.value);
    //     }
    // });

    let stringresearchfor = globalData.researchfor;
    dropdownauto(stringresearchfor);

    function dropdownauto(label) {

        const autoCompleteJS = new autoComplete({
            name: "autocomplete search",
            selector: searchbar,
            placeHolder: label,
            highlight: true,
            observer: false,
            cache: true,
            maxResults: 5,
            debounce: 100,
            threshold: 3,
            searchEngine: "strict",
            data: {
                src: async () => {

                    // if (!JSON.parse(localStorage.getItem("acData"))) {
                        // Fetch External Data Source
                        const source = await fetch(globalConf.baseUrl + '/_/fragments/autocomplete/');
                        const data = await source.json();
                        // Saves the fetched data into local storage
                        // localStorage.setItem("acData", JSON.stringify(data));

                        // Retrieve the cached data from local storage
                        // const localData = JSON.parse(localStorage.getItem("acData"));

                        // Returns Fetched data
                        return data;
                    // }

                    // return JSON.parse(localStorage.getItem("acData"));
                },
                cache: true,
            },
            trigger: (query) => {
                return query.replace(/ /g, "").length;
            },
            resultItem: {
                tag: "li",
                class: "autoComplete_result",
                element: (item, data) => {
                    item.setAttribute("data-parent", "result-item");
                },
                highlight: "autoComplete_highlight",
                selected: "autoComplete_selected"
            },
            events: {
                input: {
                    selection: (event) => {
                        const selection = event.detail.selection.value;
                        autoCompleteJS.input.value = selection;
                        setQuery(selection);
                        if (!pageSearch) {
                            const query = urlQuery();
                            redirectTo(query);
                        } else {
                            closeCleanSearch();
                            Result(selection);
                        }
                    }
                }
            }
        });

    }

}