import "./index.scss";
import { $ } from "@olmokit/dom";
import { WindowDetect } from "components/WindowDetect";
import { globalData } from "@olmokit/core/data";

/**
 * Component: SliderSector
 */
export function SliderSector() {
  console.log("SliderSector mounted.");

  SliderSectorExport('.SliderSector:slider');

}

export function SliderSectorExport(element) {
  console.log("SliderSector mounted.");

  const detect = WindowDetect();

  function sliders() {

    let glide, anchors, breakpoints, controls, lazyLoad, swipe;

    import("@olmokit/core/glide").then(({glide, Anchors, Breakpoints, Controls, LazyLoad, Autoplay, Swipe}) => {
      glide = glide;
      anchors = Anchors; 
      breakpoints = Breakpoints; 
      controls = Controls; 
      lazyLoad = LazyLoad; 
      swipe = Swipe;

      const glideOptions = {
        type: "slider",
        perView: 2,
        focusAt: 0,
        startAt: 0,
        gap: 30,
        loop: true,
        animationDuration: 600,
        animationTimingFunc: "cubic-bezier(.13,.23,.77,1)",
        peek: {
          before: 200,
          after: 200
        },
        breakpoints: {
          1024: {
            perView: 2,
            startAt: 0,
            peek: {
              before: 0,
              after: 200
            },        
          },
          600: {
            perView: 1,
            peek: {
              before: 0,
              after: 0
            }        
          }
        }
      };
    
      const slider = $(element);
    
      const sliderImages = glide(slider, glideOptions);
    
      sliderImages.mount({
          Swipe,
          LazyLoad,
          Anchors,
          Controls,
          Autoplay,
          Breakpoints
      });

      slider.classList.remove('slider-not-active');

    }); 
  }   

  const slideCounter = globalData['SliderSector0'];

  if(detect <= 576 && slideCounter > 1){    
    sliders();
  } else if(detect <= 1024 && slideCounter > 2) {
    sliders();
  } else if(detect > 1024 && slideCounter > 2){
    sliders();
  }  

}