import "./index.scss";
import {
  glide,
  Anchors,
  Autoplay,
  Breakpoints,
  Controls,
  LazyLoad,
  Swipe,
} from "@olmokit/core/glide";
import { $, $all, on, forEach } from "@olmokit/dom";
import { globalData } from "@olmokit/core/data";
import gsap from "gsap";
// import { device } from "@olmokit/core/detect";

/**
 * Component: SliderImages
 */
export function SliderImages() {
  console.log("SliderImages mounted.");

  /**
   * Default Slider
   */  
  const slide = globalData.slide;
  const peek = globalData.peek;

  const glideOptions = {
    type: "slider",
    perView: slide,
    focusAt: 0,
    startAt: 0,
    gap: 30,
    loop: true,
    animationDuration: 600,
    animationTimingFunc: "cubic-bezier(.13,.23,.77,1)",
    peek: {
      before: 0,
      after: peek
    },
    breakpoints: {
      1024: {
        perView: 2,
        startAt: 0,
        gap: 15,
        peek: {
          before: 0,
          after: 0
        },        
      },
      600: {
        perView: 1,
        gap: 0,
        peek: {
          before: 0,
          after: 0
        }        
      }
    }
  };

  const slider = $('.SliderImages:slider');

  const sliderImages = glide(slider, glideOptions);

  sliderImages.mount({
      Swipe,
      LazyLoad,
      Anchors,
      Controls,
      Autoplay,
      Breakpoints
  });  

  /**
   * Zoom Slider
   */
  const glideZoomOptions = {
    type: "slider",
    perView: 1,
    focusAt: 0,
    startAt: 1,
    gap: 0,
    loop: true,
    animationDuration: 600,
    animationTimingFunc: "cubic-bezier(.13,.23,.77,1)"
  };

  const sliderZoom = $('.SliderImages:zoom_slider');

  const sliderZoomImages = glide(sliderZoom, glideZoomOptions);

  sliderZoomImages.mount({
      Swipe,
      LazyLoad,
      Anchors,
      Controls,
      Autoplay,
      Breakpoints
  });


  /**
   * Generate some actions
   */
   const singleSlides = $all('.SliderImages:slide');  
   const zoom = $('.SliderImages:zoom');
   const singleSlidesZoom = $all('.SliderImages:zoom_image');  
   const close = $('.SliderImages:zoom_close');
   let counter;
 
   on(close, 'click', () => {
     // gsap.to(singleSlidesZoom[counter], { duration: 0.6, autoAlpha: 0, scale: 0.9});
     gsap.to(zoom, { duration: 0.3, autoAlpha: 0, delay: 0.5});
     $('body').style.overflow = 'initial';
   });
 
   forEach(singleSlides, (el) => {
     on(el, 'click', () => {
       const index = el.getAttribute('data-glide-idx');
       gsap.to(zoom, { duration:0.3, autoAlpha: 1});
       sliderZoomImages.go('='+index);
       // gsap.to(singleSlidesZoom[index], { duration: 0.6, autoAlpha: 1, scale: 1, delay: 0.2});
       $('body').style.overflow = 'hidden';
       counter = index;
     });
   });  


}
