import { $, listen } from "@olmokit/dom";
import "./index.scss";

/**
 * Component: Selectlang
 *
 * The component will be initialised simply by calling the function
 * `Selectlang();` when you need.
 */
export function Selectlang() {
  console.log("Selectlang mounted.");

  const lang = $(".Selectlang:list");
  const drop = $(".Selectlang:dropbtn");

  if (lang) {
    drop.addEventListener("click", () => {
      lang.classList.toggle("show");
    });

    // Close the dropdown menu if the user clicks outside of it
    window.onclick = function (event) {
      if (!event.target.matches(".drop")) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            openDropdown.classList.remove("show");
          }
        }
      }
    };
  }

  const $root = $(".LocaleSwitch:");

  listen("click", ".LocaleSwitch:item", (event) => {
    const $link = event.target;

    event.preventDefault();

    $root.classList.add("is-loading");

    location.href = $link.href;
  });
}
