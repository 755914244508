import "@olmokit/core/forms/checkbox";
import FormsInputMaterial from "@olmokit/core/forms/input/material";
import FormsSelectMaterial from "@olmokit/core/forms/select/material";
import "@olmokit/core/forms/textarea/filled";
import olmoforms from "@olmokit/core/olmoforms/feedback";
import "./Newsletter.scss";

/**
 * Component: FormNewsletter
 *
 */
export function FormNewsletter() {
  const newsletter = olmoforms(".FormNewsletter:",{
  sent: (formData) => {
      dataLayer.push({
        ...formData,
        event: "form sent",
        form: "Form Newsletter",
      });
    },
  });


  FormsInputMaterial();
  FormsSelectMaterial();

  return {
    destroy() {
      newsletter.destroy();
    },
  };
}
