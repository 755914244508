import { $, $all, on, addClass, forEach } from "@olmokit/dom";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import gsap from "gsap";
gsap.registerPlugin(ScrollToPlugin);

export function RetreiveData() {

  const $elements = $all('.getAnalytics');

  forEach($elements, (el) => {

    const event = el.getAttribute('data-event'); 
    const element = el.getAttribute('data-element'); 
    const element_name = el.getAttribute('data-elementname');
    const type = el.getAttribute('data-type');

    on(el, 'click', (e) => {
      // e.preventDefault();
      dataLayer.push({
        'event': event,
        'element': element,
        'element_name': element_name,
        'type': type
      });      
    });

  });

}

export function WindowDetect() {
  
  const width = window.innerWidth;  

  return width;

}

export function WindowDetectCustom(element) {
  
  const width = window.innerWidth;  

  if(width <= element){
    return true;
  }

}

export function WindowDetectMedia() {
  
  const width = window.innerWidth;

  if(width <= 576){
    return 'xs';
  } else if(width <= 768) {
    return 'sm';
  } else if(width <= 992) {
    return 'md';
  } else if(width <= 1024) {
    return 'lg'
  } else if(width <= 1200) {
    return 'xl'
  } else if(width <= 1440) {
    return 'xxl'    
  } else {
    return 'xxxl'
  }

}

export function SliderActivation(element, counter, perView = 3, perViewTabel = 2, peekleft = 0, peekright = 0, swipeThreshold = true, dragThreshold = true) {

  const slideCounter = counter;  

  function sliders() {

    let glide, anchors, breakpoints, controls, lazyLoad, swipe;

    import("@olmokit/core/glide").then(({glide, Anchors, Breakpoints, Controls, LazyLoad, Swipe}) => {
      glide = glide;
      anchors = Anchors; 
      breakpoints = Breakpoints; 
      controls = Controls; 
      lazyLoad = LazyLoad; 
      swipe = Swipe;

      const glideOptions = {
        type: "carousel",
        rewind: false,
        perView: perView,
        startAt: 0,
        focusAt: 0,
        gap: 30,
        animationDuration: 800,
        animationTimingFunc: "cubic-bezier(.13,.23,.77,1)",
        swipeThreshold: swipeThreshold,
        dragThreshold: dragThreshold,
        peek: {
          before: peekleft,
          after: peekright
        },        
        breakpoints: {
          1024: {
            perView: perViewTabel,
            startAt: 0
          },
          576: {
            perView: 1
          }
        }
      };
    
      const slider = element;
    
      const sliderImages = glide(slider, glideOptions);
  
      sliderImages.mount({
        swipe,
        lazyLoad,
        anchors,
        controls,
        breakpoints
      });      

    }); 
  }

  if(WindowDetect() <= 576 && slideCounter > 1){    
    sliders();
  } else if(WindowDetect() <= 1024 && slideCounter > perViewTabel) {
    sliders();
  } else if(WindowDetect() > 1024 && slideCounter > perView){    
    sliders();
  } else if(slideCounter == 1) {
      const $glide__slides = $('.glide__slides', element);
      addClass($glide__slides, 'noSlider');
  }

}

export function Scroll(element) {
  gsap.to(window, { duration: 0.5, scrollTo: element });
}